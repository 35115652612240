import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

function Meters(){
  return(
    <>
      <section className='section-2'>
        <div className='container'>
          <div className='row position-relative'>
            <div className='col-md-4 d-flex justify-content-center align-items-center outerCardContainer'>
                <div class="card fixedCards">
                    <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Fhomepage%2Fhero%2FEPI-HAZ-Meters.png?alt=media&token=18b84b0e-cbda-47eb-9b44-24237ec3552e" class="card-img-top" alt="Group of Hazardous Locations Rated Flow Meters" />
                    <div class="card-body contentFill">
                        <h5 class="card-title">HAZ Meters</h5>
                        <h6 class="card-subtitle mb-2 text-muted">CERTIFIED FOR HAZARDOUS LOCATIONS</h6>
                        <p class="card-text">
                            Our HAZ meters are certified for hazardous locations (flame-proof locations), with a diecast aluminum
                            explosion-proof enclosure certified to CSA/CUS, ATEX, IECEx, and KOSHA standards. Assembled with stainless
                            steel wetted materials (optional Hastelloy C-276®) the HAZ series is available in both Inline and Insertion
                            configurations.
                        </p>
                        {/* <div className='text-center'>
                            <a href=".." class="btn btn-outline-primary">View EPI-HAZ Meters</a>
                        </div> */}
                        <NavLink className='btn btn-outline-primary' tag={Link} to="/allproducts/haz">
                            View EPI-HAZ Meters
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='col-md-4 d-flex justify-content-center align-items-center outerCardContainer'>
                <div class="card fixedCards">
                    <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Fhomepage%2Fhero%2FEPI-GEN-Meters.png?alt=media&token=50d92ce3-8c2c-4566-ace6-f3b307313777" class="card-img-top" alt="Group of General Locations Rated Flow Meters" />
                    <div class="card-body contentFill">
                        <h5 class="card-title">GEN Meters</h5>
                        <h6 class="card-subtitle mb-2 text-muted">CERTIFIED FOR GENERAL LOCATIONS</h6>
                        <p class="card-text">
                            Our GEN meters are certified for use in ordinary locations (non-hazardous) and CSA/CUS Class 2252-03 & 2252-80 approved
                            instruments. Assembled with stainless steel wetted materials (optional Hastelloy C-276®) the GEN series is available in
                            both Inline and Insertion configurations.
                        </p>
                        <NavLink className='btn btn-outline-primary' tag={Link} to="/allproducts/gen">
                            View EPI-GEN Meters
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='col-md-4 d-flex justify-content-center align-items-center outerCardContainer'>
                <div class="card fixedCards">
                  <img src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Fhomepage%2Fhero%2FEPI-All-Meters.png?alt=media&token=3c315d29-4cae-4e89-8385-c36f9992698b"class="card-img-top" alt="Assortment of flow meters" />
                  <div class="card-body contentFill">
                    <h5 class="card-title">All Meters</h5>
                    <h6 class="card-subtitle mb-2 text-muted">FOR MULTIPLE APPLICATIONS</h6>
                    <p class="card-text">
                      EPI offers gas measurement solutions for your high temperature (XT), high purity (PURE), and large duct (MultiGEN) applications.
                      Additionally, our VAL series provides a more economical solution while maintaining the high standards that EPI has set for accuracy
                      and reliability.
                    </p>
                    <NavLink className='btn btn-outline-primary' tag={Link} to="/allproducts">
                      View All Meters
                    </NavLink>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Meters;